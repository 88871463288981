@import "./../../../index.scss";

// .ourServic {
//   @media (min-width: 506px) and (max-width: 876px) {
//     .container,
//     .container-sm {
//       max-width: 729px;
//     }
//   }
// }

.testimonials {
  background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/Rectangl91.png")
    no-repeat;
  background-size: cover;
  padding: 80px 0;
  // @media (max-width: 767px) {
  //   padding: 30px 0;
  // }
  @media (min-width: 600px) and (max-width: 991px) {
    .container {
      max-width: 960px !important;
    }
    // ,
    // .container-md,
    // .container-sm
  }
  .testimonialsContent {
    background-color: white;
    padding: 31px 87px 48px;
    @media (max-width: 668px) {
      padding: 31px 5px 48px;
    }
    .testimonialsHeader {
      .stars {
        text-align: center;
        img {
          padding: 0px 10.5px;
        }
      }
      h3 {
        // padding-top: 41px;
        text-align: center;
        color: #000;
        font-family: Montserrat;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 15.36px;
        @media (max-width: 560px) {
          font-size: 42px;
          letter-spacing: 5px;
        }
      }
      // @media (max-width: 663px) {
      //   h2 {
      //     font-size: 40px;
      //   }
      //   h3 {
      //     display: none;
      //   }
      // }
    }
    .testimonialsSlider {
      @media (min-width: 768px) and (max-width: 991px) {
        .container {
          // max-width: 960px !important;
        }
        // ,
        // .container-md,
        // .container-sm
      }
      .parentSlider {
        padding: 10px 0;
        display: grid !important;
        grid-template-columns: repeat(auto-fit, minmax(300px, auto));
        gap: 30px 0;
        @media (max-width: 1199px) {
          grid-template-columns: repeat(auto-fit, minmax(370px, auto));
          justify-content: center;
        }
        // grid-template-columns: repeat(auto-fit, minmax(100px , auto));
        // max-width: 800px;
        // align-content: start;
        // display: flex !important;
        // flex-wrap: wrap;
        // gap: 30px;

        .imgSlider {
          @media (max-width: 1199px) {
            margin: auto;
          }
          img {
            width: 203px;
            height: 201px;
            padding: 10px;
            box-sizing: content-box;
            border: solid $main-color 24px;
            border-radius: 269px;
            box-shadow: 0px 10px 4px 0px rgba(0, 0, 0, 0.25);
            // border-radius: 135px;
          }
        }
        .sliderText {
          padding: 40px 208px 0 88px;
          position: relative;
          @media (max-width: 1199px) {
            margin: auto;
            padding: 40px 0px 0 88px;
          }
          @media (max-width: 1199px) {
            margin: auto;
            padding: 40px 0 0 5px;
          }
          // &::before {
          //   content: url();
          //   position: absolute;
          //   top: 19%;
          //   left: -15%;
          //   bottom: auto;
          //   background: $main-color;
          //   border-radius: 0.5rem;
          //   width: 4px;
          //   height: 540px;
          // }

          &::after {
          }
          p {
            color: #6a6464;
            font-family: Montserrat;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          h6 {
            color: #6a6464;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          @media (max-width: 414px) {
            p {
              font-size: 20px;
              padding: 0px 30px 0 10px;
            }
            h6 {
              padding-left: 10px;
            }
          }
          @media (max-width: 395px) {
            p {
              padding: 0px 30px 0 20px;
            }
            h6 {
              padding-left: 20px;
            }
          }

          .imgBeforeText {
            display: flex;
            position: absolute;
            top: -15px;
            left: -10px;
            z-index: 9999999999;
          }
          .imgAfterText {
            display: flex;
            position: absolute;
            top: 65%;
            // left: -10px;
            right: 25%;
            @media (max-width: 768px) {
              top: 65%;
              // left: -10px;
              right: 1%;
            }
          }

          @media (max-width: 458px) {
            .imgBeforeText {
              display: none;
              img {
                // display: none;
              }
            }
            .imgAfterText {
              display: none;
            }
          }
        }
      }

      .slick-next {
        top: -50px !important;
        // left: 40%;
        z-index: 99999999;
      }
      .slick-prev {
        top: -50px !important;
        // left: 3%;
        z-index: 99999999;
      }
      .slick-next::before {
        content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Group 159.svg");
      }
      .slick-prev::before {
        content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Group 19.svg");
      }

      .slick-dots {
        bottom: -49px;
        // right: 50px;
        left: 360px;
        // width: 50%;
        border-radius: 16px;
        background-color: $main-color;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        max-width: 320px;
        height: 15px;
        li {
          top: -9px;
          button {
            // width: 30px;
            &::before {
              color: #e5e5e5;
              opacity: 0.75;
              font-size: 15px;
              // fill: linear-gradient(0deg, #e5e5e5 0%, #e5e5e5 100%),
              //   linear-gradient(0deg, #e5e5e5 0%, #e5e5e5 100%), #e5e5e5;
              // filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
            }
          }
        }
        li.slick-active button::before {
          opacity: 4;
          color: #fff !important;
          font-size: 35px;
          margin-left: -7px !important;
          filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.25));
        }
      }

      @media (max-width: 1199px) {
        .slick-next {
          top: 150px !important;
          // left: 40%;
          // z-index: 99999999;
        }
        .slick-prev {
          top: 150px !important;
          // left: 3%;
          // z-index: 99999999;
        }
        // .slick-next::before {
        //   content: url("./../../../assets/icon _arrow circle right_.png");
        // }
        // .slick-prev::before {
        //   content: url("./../../../assets/icon _arrow circle 2right_.png");
        // }

        .slick-dots {
          // bottom: -49px;
          // right: 50px;
          left: 25%;
          // left: auto;
          // right: auto;
          // text-align: center;
          // margin: auto;
          // width: 50%;
          // border-radius: 16px;
          // background-color: $main-color;
          // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          // max-width: 320px;
          // height: 15px;
          // li {
          //   top: -9px;
          //   button {
          //     // width: 30px;
          //     &::before {
          //       color: #e5e5e5;
          //       opacity: 0.75;
          //       font-size: 15px;
          //       // fill: linear-gradient(0deg, #e5e5e5 0%, #e5e5e5 100%),
          //       //   linear-gradient(0deg, #e5e5e5 0%, #e5e5e5 100%), #e5e5e5;
          //       // filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
          //     }
          //   }
          // }
          // li.slick-active button::before {
          //   opacity: 4;
          //   color: #fff !important;
          //   font-size: 35px;
          //   margin-left: -7px !important;
          //   filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.25));
          // }
        }
      }

      @media (max-width: 668px) {
        .slick-next {
          right: 4%;
        }
        .slick-prev {
          left: -1%;
        }
      }

      @media (max-width: 635px) {
        .slick-next {
          display: none;
          // left: 40%;
          // z-index: 99999999;
        }
        .slick-prev {
          display: none;
          // left: 3%;
          // z-index: 99999999;
        }
        .slick-next::before {
          display: none;
        }
        .slick-prev::before {
          display: none;
        }

        .slick-dots {
          // bottom: -49px;
          // right: 50px;
          left: 25%;
        }
      }

      @media (max-width: 599px) {
        .slick-dots {
          left: 15%;
        }
      }

      @media (max-width: 480px) {
        .slick-dots {
          left: 10%;
        }
      }
      @media (max-width: 410px) {
        .slick-dots {
          left: 1%;
        }
      }

      // @media (min-width: 768px) and (max-width: 1200px) {
      //   .slick-next {
      //     left: 48%;
      //   }
      //   .slick-prev {
      //     left: -5%;
      //   }
      // }

      // @media (max-width: 992px) {
      //   .parentImg {
      //     .slidText {
      //       h5,
      //       h6 {
      //         font-size: 40px !important;
      //       }
      //     }
      //   }
      // }

      // @media (max-width: 768px) {
      //   .slick-next {
      //     left: 40%;
      //   }
      //   .slick-prev {
      //     left: 3%;
      //   }

      //   .slick-dots {
      //     display: none !important;
      //   }
      // }
      // @media (max-width: 768px) {
      //   .slick-next {
      //     left: 28%;
      //   }
      // }

      // @media (max-width: 663px) {
      //   .parentImg {
      //     grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      //     gap: 0px;
      //   }
      //   .imgSlide {
      //     img {
      //       max-width: 90%;
      //     }
      //   }
      // }
      // @media (max-width: 590px) {
      //   .parentImg {
      //     .slidText {
      //       h5,
      //       h6 {
      //         font-size: 20px !important;
      //       }
      //     }
      //   }
      //   .imgSlide {
      //     img {
      //       max-width: 90%;
      //     }
      //   }
      // }
    }
  }
}
