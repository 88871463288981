// @import url("https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans:wght@400;600;700&family=Poppins:wght@400;500;600;700&family=Raleway:wght@400;700&family=Roboto:wght@400;500&family=Ubuntu:wght@400;500;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Dancing+Script&family=Inter:wght@600&family=Lisu+Bosa:wght@400;600;800&family=Montserrat:wght@500;600;700&family=Poppins:wght@400;500;600;700&family=Raleway:wght@400;700&family=Roboto:wght@400;500&family=Ubuntu:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  // list-style: none;
  // text-decoration: none !important;
  font-family: Montserrat !important;
}

$main-color: #ffc700;

body {
  // background-color: #aaa;
}
