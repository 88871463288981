@import "./../../../index.scss";

.about_us {
  @media (min-width: 0px) {
    padding-top: 50px;
  }
  @media (min-width: 1200px) {
    padding-top: 100px;
  }
  @media (min-width: 0px) {}
  .homeHeaderContent {
    // padding-top: 20px;
    h2 {
      // padding-top: 30px;
      padding-bottom: 70px;
      color: #000;
      font-family: Montserrat;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 23.5px;
      text-align: center;
    }
    @media (max-width: 510px) {
      h2 {
        font-size: 30px;
        padding-bottom: 40px;
      }
      //   h3 {
      //     font-size: 35px;
      //   }
    }
    p {
      color: #a1aeb7;
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
    }
    .btnReadMore {
      text-align: center;
      a {
        color: $main-color;
        background-color: inherit;
        border: solid $main-color 2px;
        padding: 2px 41px;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        // line-height: 31px; /* 155% */
        text-transform: uppercase;

        // img {
        //   padding-left: 32px;
        // }
      }
    }

    .btnReadMoreText {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
      button {
        text-align: center;
        color: $main-color;
        background-color: inherit;
        border: solid $main-color 2px;
        padding: 2px 41px;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        // line-height: 31px; /* 155% */
        text-transform: uppercase;
      }
    }
  }
}
