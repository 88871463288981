.hrPro {
  float: right;
  clear: both;
  background: #ffc700;
  border: none;
  width: 90%;
  height: 15px;
  opacity: 4;
}

.projectsFooter {
  padding: 20px 0;
  background: #000;
  margin-top: 31px;
  margin-bottom: 30px;
  .proFoot {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;
    div {
      max-width: 370px;
    }
  }
  h2 {
    color: #fff;
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
  }
  h3 {
    color: #ffc700;
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
  }
  img {
    width: 301px;
    // height: 301px;
    // padding-left: 20px;
  }
  .img2,
  .img3 {
    width: 244px;
    // height: 244px;
    // border-radius: 50%;
  }
  @media (max-width: 1399px) {
    .proFoot {
      // align-items: center;
      gap: 20px;
      div {
        // max-width: 370px;
      }
    }

    img {
      width: 250px;
      // height: 301px;
      // padding-left: 20px;
    }
    .img2,
    .img3 {
      width: 200px;
      // height: 244px;
      // border-radius: 50%;
    }
  }

  @media (max-width: 1199px) {
    .proFoot {
      // align-items: center;
      // gap: 20px;
      div {
        // max-width: 370px;
      }
    }

    img {
      width: 200px;
      // height: 301px;
      // padding-left: 20px;
    }
    .img2,
    .img3 {
      width: 150px;
      // height: 244px;
      // border-radius: 50%;
    }
  }
  @media (max-width: 1199px) {
    .proFoot {
      // align-items: center;
      // gap: 20px;
      div {
        // max-width: 370px;
      }
    }

    img {
      width: 180px;
      // height: 301px;
      // padding-left: 20px;
    }
    .img2,
    .img3 {
      width: 130px;
      // height: 244px;
      // border-radius: 50%;
    }
  }
  @media (max-width: 503px) {
    // text-align: center;
    // width: 100%;
    .proFoot {
      // align-items: center;
      justify-content: center;
      // gap: 20px;
      div {
        // max-width: 370px;
      }
    }

    img {
      width: 150px;
      // height: 301px;
      // padding-left: 20px;
    }
    .img2,
    .img3 {
      width: 100px;
      // height: 244px;
      // border-radius: 50%;
    }
  }
  @media (max-width: 413px) {
    img {
      width: 140px;
    }
    .img2,
    .img3 {
      width: 90px;
    }
  }
  @media (max-width: 392px) {
    h2,
    h3 {
      font-size: 60px;
    }
  }
}
