@import "./../../index.scss";

.contactUs {
  // padding: 200px 0 100px;
  padding-bottom: 200px;
  // height: 100%;
  // background: url("./../../assets/Rectangle 5447.png") no-repeat;
  background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/contact/Rectangl5447.png") no-repeat;
  background-size: cover;
  background-position: 0% 53%;
  // background-size: contain;
  // .contContent {
  //   padding: 200px 0;
  // }
  @media (max-width: 991px) {
    padding: 0px 0 100px;
  }
  .headContact {
    padding-bottom: 64px;
    @media (max-width: 767px) {
      padding-bottom: 30px;
    }
    padding-left: 40px;
    // position: relative;
    // top: -200px;
    // bottom: 203.5px;

    // z-index: 0;
    h1 {
      padding-top: 200px;
      color: #000;
      font-family: Montserrat;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: 991px) {
        padding-top: 150px;
      }
    }
    p {
      max-width: 900px;
      color: #000;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      letter-spacing: 2px;
    }
    @media (max-width: 520px) {
      padding-left: 10px;
      h1 {
        font-size: 60px;
      }
      p {
        font-size: 20px;
      }
    }
  }

  .contact {
    border: 1px solid #fff;
    color: white;
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(10px);
    // padding: 98px 0 475px;
    padding: 0 50px 100px;
    // display: flex;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px 180px;
    @media (max-width: 520px) {
      padding: 10px 20px 80px;
    }
    .contactLeft {
      padding-top: 50px;
      h4,
      p {
        padding-left: 10px;
      }
      h4 {
        font-size: 24px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        line-height: 31.992px;
      }
      @media (max-width: 520px) {
        padding-top: 20px;
        h4 {
          text-align: center;
        }
      }

      .contactInfo {
        display: flex;
        padding-left: 10px;
        p {
          font-size: 16px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 700;
          a {
            color: white;
            text-decoration: none;
          }
          span {
            // padding-left: 10px;
          }
        }
        img {
          width: 19.707px;
          height: 17.376px;
        }
      }
      .map {
        padding-top: 20px;
        max-width: 504px;
        iframe {
          // width: 504px;
          width: 100%;
          height: 717px;
        }
      }
    }

    .contactRight {
      position: relative;
      @media (min-width: 992px) {
        &::before {
          content: "";
          position: absolute;
          top: 7%;
          left: -15%;
          bottom: auto;
          background: $main-color;
          border-radius: 0.5rem;
          width: 4px;
          height: 904px;
        }
      }

      @media (max-width: 991px) {
        &::before {
          content: "";
          position: absolute;
          top: -1%;
          left: 5%;
          bottom: auto;
          background: $main-color;
          border-radius: 0.5rem;
          width: 80%;
          height: 4px;
        }
      }

      h4 {
        color: $main-color;
        font-size: 48px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        @media (max-width: 520px) {
          font-size: 40px;
        }
      }
      p {
        font-size: 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
      }
      form {
        padding-top: 100px;
        label {
          padding: 10px;
          font-size: 16px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 700;
        }
        input,
        textarea,
        .form-select-lg {
          border: 1px solid #fff;
          background: rgba(0, 0, 0, 0.35);
          backdrop-filter: blur(10px);
          color: white;
          &::placeholder {
            color: white;
            font-size: 16px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
          }
        }

        input {
          padding: 20px 10px;
        }
        .form-select-lg {
          padding: 20px 20px;
        }
        .form-select {
          background-image: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/contact/Grou.svg");
          background-repeat: no-repeat;
          background-position: right 1rem center;
          background-size: auto;
        }
        textarea {
          height: 100%;
          height: 260px;
          padding-top: 25px;
        }

        // .iconInInput {
        //   position: relative;
        //   img {
        //     position: absolute;
        //     top: 20px;
        //     right: 20px;
        //   }
        // }

        .btnSubmit {
          display: flex;
          justify-content: flex-end;
          @media (max-width: 991px) {
            justify-content: center;
          }
          margin-top: 96px;
          position: relative;
          button {
            background-color: $main-color;
            border-radius: 8px;
            box-shadow: -1px 4px 3px 0px rgba(255, 255, 255, 0.25);
            color: #000;
            font-size: 14px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            padding: 15px 50px;
          }
        }
      }
    }

    // .dropdown-menu {
    //   transform: translate(-175px, 35px) !important;
    //   @media (min-width: 1400px) and (max-width: 1451px) {
    //     transform: translate(22px, 35px) !important;
    //   }
    //   @media (min-width: 1200px) and (max-width: 1271px) {
    //     transform: translate(22px, 35px) !important;
    //   }
    //   @media (min-width: 992px) and (max-width: 1091px) {
    //     transform: translate(22px, 35px) !important;
    //   }
    //   @media (min-width: 768px) and (max-width: 851px) {
    //     transform: translate(22px, 35px) !important;
    //   }
    //   @media (max-width: 671px) {
    //     transform: translate(22px, 35px) !important;
    //   }
    // }
    // .dropdown-item {
    //   // color: #fff;
    //   font-family: Inter;
    //   font-size: 20px !important;
    //   font-style: normal;
    //   font-weight: 400;
    // }

    // .dropdown-toggle::after {
    //   content: url("./../../assets/Grou.svg") !important;
    //   display: inline-block;
    //   margin-left: 0.255em;
    //   vertical-align: 0;
    //   border-top: none;
    //   border-right: none;
    //   border-bottom: 0;
    //   border-left: none;
    // }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
      // position: absolute;
      // background: #4a4a4a;
      // font-size: 12px;
      // font-family: Inter;
      // font-style: normal;
      // font-weight: 400;
      // line-height: 3;
      // text-align: center;
      // left: -90px !important;
      // right: 50px;
    }

    // .dropdown-item:focus,
    // .dropdown-item:hover {
    //   color: black !important;
    //   background-color: $main-color;
    // }

    .dropdown,
    .dropdown-center,
    .dropend,
    .dropstart,
    .dropup,
    .dropup-center {
      // position: relative;
      // left: 410px;
      // top: 43px;
      // position: absolute;
      // top: 20px;
      // right: 20px;
      // z-index: 999999999999999999999999999;
    }

    // .formContact {
    //   div {
    //     position: relative;
    //     &:nth-child(1) {
    //       input {
    //         padding-left: 12px !important;
    //       }
    //     }
    //     &:nth-child(2) {
    //       &::before {
    //         position: absolute;
    //         content: url("./../../assets/Feather Icon.svg") !important;
    //         top: 8px;
    //         left: 15px;
    //       }
    //     }
    //     &:nth-child(3) {
    //       &::before {
    //         position: absolute;
    //         content: url("./../../assets/ic_sharp-phone.svg") !important;
    //         top: 8px;
    //         left: 15px;
    //       }
    //     }
    //     &:nth-child(4) {
    //       &::before {
    //         position: absolute;
    //         content: url("./../../assets/mdi_company.svg") !important;
    //         top: 8px;
    //         left: 15px;
    //       }
    //     }
    //     &:nth-child(5) {
    //       &::before {
    //         position: absolute;
    //         content: url("./../../assets/clarity_world-solid.svg") !important;
    //         top: 8px;
    //         left: 15px;
    //       }
    //     }
    //     &:nth-child(7) {
    //       &::before {
    //         position: absolute;
    //         content: url("./../../assets/fluent_attach-12-filled.svg") !important;
    //         top: 8px;
    //         left: 15px;
    //       }
    //     }
    //   }
    // .btnSubmit {
    //   display: flex;
    //   justify-content: center;
    //   margin-top: 96px;
    //   position: relative;
    //   button {
    //     background-color: $main-color;
    //     width: 972px;
    //     &::after {
    //       position: absolute;
    //       // background-color: red;
    //       position: absolute;
    //       content: url("./../../assets/FeatherIcon.svg") !important;
    //       // padding: 50px;
    //       top: 4px;
    //       // left: 5px;
    //       // right: 500px;
    //       margin-left: 10px;
    //       z-index: 9999999999;
    //     }
    //   }
    // }

    //   input {
    //     padding-left: 43px;
    //   }

    //   textarea {
    //     height: 360px;
    //   }

    //   p {
    //     color: rgba(0, 0, 0, 0.8);
    //     text-align: center;
    //     font-size: 20px;
    //     font-family: Open Sans;
    //     font-weight: 600;
    //     margin-top: 64px;
    //   }
    // }
  }
}
