@import "./../../../index.scss";

.ourProjects {
  // margin-top: 670px;
  // @media (max-width: 668px) {
  //   margin-top: 550px;
  // }
  // @media (max-width: 428px) {
  //   margin-top: 400px;
  // }
  // padding: 100px 0;
  padding-bottom: 100px;
  .headOurPro {
    h3 {
      // color: $main-color;
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      // line-height: 31px;
      letter-spacing: 17.5px;
      text-transform: uppercase;
    }
    h4 {
      padding-top: 32px;
      color: #ffc700;
      text-align: center;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      // line-height: 21px;
    }
    // hr {
    //   background-color: #000000;
    //   width: 583px;
    //   height: 10px;
    //   border-top-right-radius: 20px;
    //   border-bottom-right-radius: 20px;
    //   opacity: 4;
    // }
    // .textMop {
    //   display: none;
    // }
    @media (max-width: 768px) {
      h3 {
        font-size: 25px;
        letter-spacing: 10px;
      }
      h4 {
        font-size: 40px;
      }
      //   hr {
      //     width: 483px;
      //     @media (max-width: 510px) {
      //       width: 300px;
      //     }
      //   }
      // .textDec {
      //   display: none;
      // }
      // .textMop {
      //   display: block;
      // }
    }

    @media (max-width: 503px) {
      h3 {
        font-size: 25px;
        letter-spacing: 5px;
      }
      h4 {
        font-size: 23px;
      }
    }
    @media (max-width: 503px) {
      h3 {
        font-size: 25px;
        letter-spacing: 3px;
      }
      // h4 {
      //   font-size: 23px;
      // }
    }
  }
  .slider {
    padding-top: 70px;
    .slick-track {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      // display: flex;
      // justify-content: center;
      // flex-wrap: wrap;
    }
    .imgSlide {
      // padding-right: 30px;
      padding: 0px 15px;
      img {
        width: 400px;
        height: 475px;
      }
      @media (max-width: 960px) {
        // padding-right: 0px;
      }
    }
    @media (min-width: 866px) {
      .slick-dots {
        display: none !important;
      }
    }
    .slick-next {
      right: -33px;
    }
    .slick-prev {
      left: -82px;
      z-index: 99999999;
    }
    .slick-next::before {
      // content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/our services/icon _arrow circle -right_.png");
      content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Group 159.svg");
    }
    .slick-prev::before {
      // content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/our services/icon _arrow circle 2right_.png");
      content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Group 19.svg");
    }

    .parentImg {
      position: relative !important;
      width: 100%;
      // background-color: red;
      // margin: auto;
      // display: flex;
      // justify-content: center !important;
      // align-items: center;
      .slidText {
        position: absolute;
        width: 100%;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-wrap: wrap;
        div {
          color: #f2f5f7;
          background: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(3.5px);
          // margin-right: 30px;
          margin: 0px 15px;
          padding: 15px;
          width: 400px;
          @media (max-width: 961px) {
            margin-right: 0;
          }
          // width: 100%;
        }
        h5 {
          font-size: 23px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 700;
        }
        h6 {
          font-size: 21px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    @media (min-width: 992px) and (max-width: 1425px) {
      .slick-next {
        left: 98%;
      }
      .slick-prev {
        left: -4%;
      }
    }
    @media (min-width: 992px) and (max-width: 1100px) {
      // overflow: hidden;
      margin: auto;
      max-width: 900px;
      .slick-next {
        left: 97%;
      }
      .slick-prev {
        left: -6%;
      }
    }
    @media (max-width: 865px) {
      .slick-next {
        top: 500px !important;
        left: 80%;
        display: none !important;
      }
      .slick-prev {
        top: 500px !important;
        left: 10%;
        z-index: 99999999;
        display: none !important;
      }
    }
  }

  @media (max-width: 440px) /* and (max-width: 575px) */ {
    .slider {
      .imgSlide {
        // padding-right: 30px;
        padding: 0px 15px;
        img {
          width: 100%;
          // height: 475px;
        }
      }
      .parentImg {
        .slidText div {
          margin: 0px 15px;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .slider {
      .imgSlide {
        // padding-right: 30px;
        // padding: 0px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          // width: 100%;
          // height: 475px;
        }
      }
      .parentImg {
        .slidText div {
          // margin: 0px 15px;
        }
      }
    }
  }

  @media (min-width: 441px) /* and (max-width: 575px) */ {
    .slider {
      .imgSlide {
        // padding-right: 30px;
        // padding: 0px 15px;
        img {
          // width: 100%;
          // height: 475px;
        }
      }
      .parentImg {
        .slidText {
          // width: 400px;
          // margin: auto;
          // margin-left: 15px;
          div {
            // margin: 0px 15px;
            margin: 0;
          }
        }
      }
    }
  }

  @media (min-width: 200px) /* and (max-width: 575px) */ {
    .slider {
      .imgSlide {
        // padding-right: 30px;
        // padding: 0px 15px;
        img {
          // width: 400px;
          // height: 475px;
        }
      }
    }
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }

  @media (min-height: 740px) {
  }
}
