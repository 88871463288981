@import "./../../../index.scss";

.containerItemDetails {
  @media (min-width: 892px) and (max-width: 991px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      // max-width: 960px !important;
    }
  }
  .container {
    @media (max-width: 991px) {
      // max-width: 800px;
      .container,
      .container-md,
      .container-sm {
      }
    }
  }

  @media (max-width: 400px) {
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      // width: 100%;
      // padding-right: 5px;
      // padding-left: 5px;
    }
  }
}

.itemDetailsHeader {
  position: relative;
  background: 
  // url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/services/Recta5446.png")
    no-repeat;
  height: calc(100vh - 160px);
  background-size: cover;
  // object-fit: contain;
  width: 100%;
  // background-position: center;
  // background-position-x: right;
  // background-position-y: 70%;

  @media (max-width: 452px) {
    // background-position-x: 96%;
  }
  padding-top: 32%;
  @media (max-width: 991px) {
    padding-top: 190px;
  }
  h2 {
    color: white;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }
  h3 {
    color: $main-color;
    font-family: Montserrat;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    @media (max-width: 502px) {
      font-size: 40px;
      text-align: start;
      // letter-spacing: 10px;
    }
  }

  @media (max-width: 1000px) {
    .textServicesDetails {
      position: absolute;
      bottom: 60px;
      padding-left: 30px;
    }
  }
}

.itemDetails {
  padding: 100px 0 100px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
  // gap: 30px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  h2 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    @media (max-width: 502px) {
      font-size: 35px;
      // text-align: start;
      // letter-spacing: 10px;
    }
  }
  .descriptionMain {
    padding: 0px 100px 0;
    @media (max-width: 510px) {
      padding: 40px 10px 0;
      // text-align: start;
    }
  }
  p {
    // max-width: 1100px;
    // padding: 40px 100px 0 ;
    text-align: center;
    color: #000;
    text-align: center;
    letter-spacing: 2px;
    // leading-trim: both;
    // text-edge: cap;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    text-transform: capitalize;
    // @media (max-width: 502px) {
    //   padding: 56px 15px;
    //   // text-align: start;
    // }
  }

  .descriptionWorks {
    padding: 20px 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      text-align: start;
      line-height: 30px;
      &::before {
        content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/Ellipse 798.svg");
        padding-right: 15px;
      }
    }
    @media (width <= 991px) {
      padding: 20px 10px;
      justify-content: center;
      flex-direction: column;
      p {
        text-align: center;
        // line-height: 30px;
        &::before {
          // content: url("./../../../assets/services/Ellipse 798.svg");
          // padding-right: 15px;
        }
      }
    }
  }

  .contDetails {
    // text-align: center;
    .imgsTop {
      max-width: 1112px;
      margin: auto;
      .itemDetailsSlider {
        max-height: 739px;
        // height: 739px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .slick-arrow {
        // background-color: red;
      }
      .slick-next {
      }
      .slick-prev {
        left: -5%;
      }
      .slick-next::before {
        content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/our services/icon _arrow circle right_.png");
      }
      .slick-prev::before {
        content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/our services/icon _arrow circle 2right_.png");
      }
      @media (max-width: 1239px) {
        .slick-next {
          right: -1%;
          z-index: -1;
        }
        .slick-prev {
          left: -3%;
        }
      }
      @media (max-width: 1199px) {
        .slick-next {
          right: -3%;
          z-index: -1;
        }
        .slick-prev {
          left: -6%;
        }
      }
      @media (max-width: 1064px) {
        .slick-next {
          right: -1%;
          z-index: -1;
        }
        .slick-prev {
          left: -4%;
        }
      }

      @media (max-width: 1019px) {
        .slick-next {
          display: none !important;
        }
        .slick-prev {
          display: none !important;
        }
      }
      // display: flex;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: space-between;
      // align-content: space-between;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      // gap: 37px 240px;
      // gap: 37px;

      // img {
      //   max-width: 427px;
      //   max-height: 358px;
      //   @media (max-width: 1399px) {
      //     margin: auto;
      //   }
      //   // width: 100%;
      // }
      // @media (min-width: 992px) and (max-width: 1399px) {
      //   gap: 37px 440px;
      //   img {
      //     height: 100%;
      //   }
      // }
      // .imgDetails1 {
      //   max-width: 743px;
      //   max-height: 746px;
      //   @media (max-width: 1199px) {
      //     margin: auto;
      //   }
      //   @media (max-width: 940px) {
      //     width: 100%;
      //   }
      // }
      // .imgsRight {
      //   img {
      //     width: 100%;
      //   }
      //   .imgDetails2 {
      //     max-width: 427px;
      //     max-height: 358px;
      //     margin-top: 31px;
      //   }
      //   @media (max-width: 1399px) {
      //     margin: auto;
      //     .imgDetails2 {
      //       margin-top: 0px;
      //     }
      //   }

      //   @media (max-width: 891px) {
      //     img {
      //       max-width: 680px;
      //       width: 100%;
      //     }
      //     .imgDetails2 {
      //       margin: auto;
      //       margin-top: 30px;
      //       max-width: 700px;
      //       margin-left: -18px;
      //       width: 102.5%;
      //       // width: 100%;
      //     }
      //   }

      //   @media (max-width: 767px) {
      //     img {
      //       max-width: 680px;
      //       width: 100%;
      //     }
      //     .imgDetails2 {
      //       margin: auto;
      //       margin-top: 30px;
      //       max-width: 750px;
      //       margin-left: -12px;
      //       width: 102.5%;
      //       // width: 100%;
      //     }
      //   }
      // }
    }
    .imgsButtom {
      padding-top: 56px;
      .image-gallery {
        // background: url("https://1.bp.blogspot.com/-GeKfP4Oict4/UjHW2XDOs0I/AAAAAAAASW8/JY23l6bOCiM/s1600/%D9%85%D9%86%D8%A7%D8%B8%D8%B1+%D8%AC%D9%85%D9%8A%D9%84%D8%A9+%287%29.jpg") no-repeat;
      }
      // margin-top: 60px;
      // max-width: 1200px;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      // justify-content: center;
      // align-items: center;
      // align-content: start;
      // gap: 30px;+
      .image-gallery-thumbnail .image-gallery-thumbnail-image {
        // vertical-align: middle;
        // width: 100%;
        // line-height: 0;
        height: 100px;
      }
      img {
        // max-width: 380px;
        // width: 100%;
        // height: 100%;
        height: 650px;
        @media (max-width: 550px) {
          height: 550px;
        }
        // min-height: ;
      }
      .image-gallery-slide .image-gallery-image {
        width: 100%;
        // object-fit: cover;
        // object-fit: fill;
      }

      .image-gallery-content.fullscreen {
        // img{
        //   height: 700px;
        // }
        .image-gallery-content {
        }
      }

      .fullscreen {
        .image-gallery-swipe {
          img {
            // height: 900px;
            height: calc(100vh - 120px);
          }
        }
      }

      // .imgDetails3 {
      //   max-width: 560px;
      //   width: 100%;
      //   max-height: 358.324px;
      // }
      // .imgDetails4 {
      //   max-width: 631px;
      //   // width: 641px;
      //   width: 100%;
      //   height: 358px;
      //   // margin-left: 27px;
      // }
      // @media (max-width: 1199px) {
      //   img {
      //     height: 100%;
      //   }
      // }
      @media (max-width: 767px) {
        // display: none;
        // width: 100%;
        // margin: auto;
        img {
          // width: 100% !important;
          // height: 100%;
        }
        // .imgDetails3 {
        //   max-width: 680px;
        // }
        // .imgDetails4 {
        //   margin-top: 20px;
        //   max-width: 680px;
        // }
      }
    }
    .imgDetails {
      // width: 400px;
      // height: 370px;
      @media (max-width: 502px) {
        // width: 360px;
      }
    }
  }
}
