@import "./../../../index.scss";

.services {
  padding: 100px 0;
  @media (max-width: 767px) {
    padding: 50px 0;
  }
  .servHead {
    text-align: center;
    h2 {
      color: #000;
      font-size: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      @media (max-width: 420px) {
        font-size: 25px;
      }
    }
    h3 {
      color: $main-color;
      text-align: center;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      // line-height: 418%; /* 133.76px */
      @media (max-width: 420px) {
        font-size: 23px;
      }
    }
    p {
      // max-width: 800px;
      padding-bottom: 30px;
      color: #000;
      font-size: 18px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 2px;
    }
  }

  .servCards {
    .boxes {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      align-items: center;
      justify-content: center;
      gap: 30px;
      .box {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 20px;
        .itemContent {
          position: relative !important;
          width: 100%;
          .text {
            color: white;
            position: absolute;
            width: 100%;
            bottom: 0px;
            transition: all 0.3s;
            div {
              border-radius: 0px 0px 20px 20px;
              background: rgba(217, 217, 217, 0.3);
              backdrop-filter: blur(9px);
              padding: 15px;
              height: 100px;
            }
            h5 {
              // color: #050000;
              font-size: 20px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: uppercase;
            }
            // h6 {
            //   // color: #21203c;
            //   font-size: 18px;
            //   font-family: Open Sans;
            //   font-style: normal;
            //   font-weight: 400;
            // }
          }
          .item {
            img {
              border-radius: 20px;
              width: 100%;
              height: 340px;
              transition: all 0.3s;
            }
            // &:hover {
            //   img {
            //     @media (min-width: 600px) {
            //       transform: scale(1.1);
            //     }
            //   }
            // }
          }
          h6 {
            padding: 5px;
          }
          h5 {
            padding: 0px 29px;
          }
          
          &:hover {
            img,.text {
              @media (min-width: 600px) {
                transform: scale(1.1);
              }
            }
            .text {
              margin-bottom: -13px;
            }
          }
        }
      }
    }
  }
}
