.projectDetailsHeader {
  // background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/Rectang116.png") no-repeat;
  // height: calc(100vh - 170px);
  // background-size: cover;
  // background-position-x: right;
  // background-position-y: 70%;
  .video {
    width: 100%;
    height: calc(100vh - 160px);
  }
  @media (max-width: 452px) {
    // background-position-x: 96%;
  }
}

.ProjectDetails {
  .ProjectDetMain {
    *{
      // list-style: initial !important;
    }
    ol {
      // list-style: decimal !important;
    }
    padding-top: 37px;
    background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/Vecr.png")
      no-repeat;
    background-size: contain;
    background-position-x: 100%;
    @media (max-width: 981px) {
      background-size: cover;
    }

    // height: 100vh;
    // position: relative;
    // z-index: -1;

    // top: -400px;
    // bottom: 400px;
    // left: 0;
    // right: 0;
    // padding: 500px 0 1px;
    // background: url("./../../../assets/Vectorn.png") no-repeat;
    // width: 2000px !important;
    // background-position: 50% -100%;
    h2 {
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      // line-height: 100%;
      text-transform: uppercase;
    }
    p {
      // max-width: 900px;
      color: #000;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 41px;
      // text-transform: lowercase;
      text-align: center;
    }

    .imgs {
      padding-top: 70px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      align-items: center;
      justify-content: center;
      gap: 30px;
      // margin: auto;
      img {
        // text-align: center;
        margin: auto;
        // width: 100%;
        // height: 100%;
        width: 450px;
        height: 450px;
        @media (max-width: 470px){
          width: 100%;
        }
      
      }
    }
    @media (max-width: 767px) {
      padding: 37px 0px 1px;
      background-size: contain;
      top: -140px;
      // left: 3%;
      max-width: 700px;
      h2 {
        font-size: 35px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

.featrues {
  padding: 100px 0 10px;
  // margin-top: -27%;
  // @media (max-width: 958px) {
  //   margin-top: -19%;
  // }
  @media (max-width: 500px) {
    // margin-top: -75%;
  }
  h2 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
  }
  p {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    // text-transform: lowercase;
    text-align: center;
  }
  .featruesSlider {
    padding: 50px 0;
    margin: auto;
    .slidBut {
      padding-top: 40px;
    }

    .imgSlide {
      padding: 0px 15px;
      @media (max-width:576px) {
        padding: 0;
      }
      img {
        width: 400px;
        height: 400px;
        margin: auto;
      }
    }
  }
}
