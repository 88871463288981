@import "./../../index.scss";

.navigation {
  // background-color: red;
  // height: 170px;
  // min-height: 170px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.navbar {
  // height: 100%;
  min-height: 160px;
  @media (max-width: 991px) {
    min-height: 108px;
  }
  // display: flex;
  // align-items: center;
  // justify-content: center;

  // position: absolute;
  // top: 230px;
  // right: 0;
  // left: 0;
  // z-index: 9999999999999;
  // @media (hight:600px) {
  //   background-color: red !important;
  //   position: relative !important;
  // }
  @media (min-width: 992px) and (max-width: 1020px) {
    // right: 40px;
  }
}

.navLogo {
  // padding-top: 50px;
  a {
    border-bottom: none !important;
  }
  // position: absolute;
  // top: 50px;
  // width: 100%;
  // text-align: center;
  // display: none;
  .navLogoMob {
    // display: none;
  }
  @media (max-width: 991px) {
    // text-align: start;
    .navLogoMob {
      // left: 50px;
      // display: block;
      // position: absolute;
      // top: -30px;
    }
    .navLogoDik {
      // display: none;
    }
  }
}

.navbar-toggler-icon {
  background-image: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Grou19401.png");
  color: red;
}

.navbar-toggler {
  border: none;
}

.navbar-expand-lg .navbar-collapse {
  // border-radius: 22px;
  // background: rgba(0, 0, 0, 0.4);
  // backdrop-filter: blur(22.5px);
}

// .dropdown-item {
//   color: #fff;
//   font-family: Inter;
//   font-size: 20px !important;
//   font-style: normal;
//   font-weight: 400;
// }

// .dropdown-toggle::after {
//   content: url("./../../assets/Vectofr.svg") !important;
//   display: inline-block;
//   margin-left: 0.255em;
//   vertical-align: 0;
//   border-top: none;
//   border-right: none;
//   border-bottom: 0;
//   border-left: none;
// }

// .navbar-expand-lg .navbar-nav .dropdown-menu {
//   position: absolute;
//   background: #4a4a4a;
//   font-size: 12px;
//   font-family: Inter;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 3;
//   text-align: center;
//   // right: 50px;
//   left: -90px;
// }

// .dropdown-item:focus,
// .dropdown-item:hover {
//   color: black !important;
//   background-color: $main-color;
// }

nav {
  // background-color: red;
  // position: relative;
  // position: fixed !important;
  // top: 0;
  // z-index: 9999999999999;

  // align-items: center;
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  .active {
    // color: $main-color !important;
    border-bottom: solid $main-color 6px;
  }
  a {
    // padding: 0 35px;
    margin: 0 33px;
    padding-bottom: 11px;
    // gap: 50px;
    // color: #fff !important;

    text-decoration: none;
    // color: #dadada !important;
    // font-size: 28px !important;
    // font-family: Ubuntu;
    // font-weight: 700;
    // text-transform: uppercase;

    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  @media (max-width: 1203px) {
    a {
      padding: 5px;
    }
  }
}

// @media (max-width: 991px) {
//   .iconMenu {
//     margin-right: 20px;
//     position: absolute;
//     top: -60px;
//     right: 0;
//   }
//   .navbar {
//     top: 90px;
//   }
// }

// @media (max-width: 991px) {
//   .navigation {
//     background: red !important;
//   }
// }
