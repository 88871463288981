@import "./../../../index.scss";

.ourDevelopers {
  padding: 37px 0 70px;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  margin-bottom: 30px;
  background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/Rectangle 5449.png") no-repeat;
  background-size: cover;
  .ourDeveHead {
    padding-top: 10px;
    text-align: center;
    h2 {
      padding: 50px 0;
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      // line-height: 418%;
      letter-spacing: 17.5px;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 39px;
        // text-align: center;
        letter-spacing: 7px;
      }
    }
    // p {
    //   color: #ffc700;
    //   text-align: center;
    //   font-family: Ubuntu;
    //   font-size: 15px;
    //   font-style: normal;
    //   font-weight: 500;
    //   // line-height: 418%;
    //   text-transform: uppercase;
    // }
  }
  // .ourDeveContent {
  .cardsClients {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-wrap: wrap;
    // gap: 10px;
    padding-top: 20px;
    // justify-items: end;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    // gap: 20px 10px;
    gap: 30px;
    .card {
      border-radius: 10px;
      background-color: #ffffff;
      height: 200px;
      // text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 100%;
      // width: 300px !important;
      .cardContent {
        // background-color: red;
        // max-width: 100%;
        img {
          // text-align: center;
          // height: 100%;
          // padding: 70px 30px;
          // max-width: 900px;
        }
        .imgHover {
          display: none;
        }
      
        // transition: all 20s ease-in-out;
        .imgMainParent {
          width: 100%;
        }
      }
      &:hover {
        .imgMain {
          display: none;
        }
        .imgHover {
          display: block;
        }
      }
    }
  }

  .cardsTop {
    // padding-top: 20px;
    // justify-items: end;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(167px, 1fr));
    // gap: 20px 10px;
    gap: 20px;
    .card {
      border-radius: 10px;
      background-color: #ffffff;
      height: 126px;
      // text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 100%;
      // width: 300px !important;
      // max-width: 167px !important;
      .cardContent {
        // width: 100%;
        img {
          // text-align: center;
          // height: 126px;
          // height: 100%;
          width: 100%;
          // padding: 70px 30px;
        }
      }
    }
  }

  .cardsPartner {
    // padding-top: 50px;
    // justify-items: end;
    // display: grid;
    // align-items: center;
    // justify-content: center;
    // grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    // // gap: 20px 10px;
    // gap: 30px;
    @media (min-width: 1200px) {
      max-width: 90%;
      margin: auto;
    }
  }

  @media (min-width: 768px) {
    .cards {
      // display: grid;
    }
    .cardsSlid {
      // display: none;
    }
  }
  @media (max-width: 767px) {
    .cardsMain {
      // display: grid;
      // display: none;
    }
    .cardsSlid {
    }
  }
  // }
}
