@import "./../../../index.scss";

.ourServic {
  // margin-top: 60px;
  @media (max-width: 668px) {
    // margin-top: 550px;
  }
  @media (max-width: 428px) {
    // margin-top: 400px;
  }
  @media (min-width: 506px) and (max-width: 876px) {
    .container,
    .container-sm {
      max-width: 729px;
    }
  }
}

.ourServices {
  padding: 80px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .ourServHead {
    h2 {
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      // line-height: 31px;
      letter-spacing: 17.5px;
      text-transform: uppercase;
    }
    h3 {
      padding-top: 41px;
      color: #ffc700;
      text-align: center;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      // line-height: 21px;
    }
    @media (max-width: 608px) {
      h2 {
        font-size: 30px;
        letter-spacing: 10px;
      }
      h3 {
        padding-top: 30px;
        font-size: 20px;
      }
    }
  }
  .ourServSlid {
    .boxes {
      display: flex !important;
    }
    .imgSlide {
      img {
        width: 100%;
        height: 523px;
        @media (min-width: 1000px){
          margin-left: 12px;
        }
        @media (max-width: 510px) {
          width: 90%;
        }
        @media (max-width: 600px) {
          height: 300px;
          // margin: auto;
        }
      }
    }
    .slick-dots li button::before {
      font-size: 15px;
    }
    .slick-next {
      top: 500px !important;
      left: 40%;
      z-index: 99999999;
      display: none !important;
    }
    .slick-prev {
      top: 500px !important;
      left: 3%;
      z-index: 99999999;
      display: none !important;
    }

    .slick-dots {
      bottom: -50px;
      // bottom: 30px;
      right: 28%;
      // width: 50%;
      li {
        button {
          // width: 30px;
          &::before {
            // color: $main-color;
          }
        }
      }
      li.slick-active button::before {
        opacity: 0.75;
        color: $main-color !important;
      }
    }
    .slick-next::before {
      content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/our services/icon _arrow circle right_.png");
    }
    .slick-prev::before {
      content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/our services/icon _arrow circle 2right_.png");
    }
    .parentImg {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
      gap: 30px;

      .desktop-title {
        display: block;
      }

      .mobile-title {
        display: none;
      }

      @media (max-width: 768px) {
        .desktop-title {
          display: none;
        }

        .mobile-title {
          display: block;
          font-size: 35px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          @media (max-width: 825px) {
            padding-top: 40px;
          }
          @media (max-width: 768px){
            text-align: center;
          }
          @media (max-width: 450px){
            font-size: 28px;
            padding-right: 10px;
            padding-left: 10px;
          }
        }
      }

      .slidText {
        // ul {
        //   li {
        //     list-style: disc !important;
        //   }
        // }
        div {
          // background: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(3.5px);
          color: #000;
          h5 {
            font-size: 45px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            @media (max-width: 825px) {
              padding-top: 40px;
            }
          }
          h6 {
            color: #000;
            padding-bottom: 10px;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px;
            letter-spacing: 2px;
          }
          p {
            position: relative;
            padding-left: 20px;
            font-size: 18px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 2px;
            height: max-content;
            &:not(:empty)::before {
              content: "";
              position: absolute;
              left: 5px;
              top: 10px;
              // transform: translate(-100px, 20%);
              width: 4px;
              height: 4px;
              background-color: black;
              border-radius: 50%;
            }
          }
        }
        .firstCard {
          h6 {
            display: none;
          }
          p {
            &:not(:empty)::before {
              content: "";
              position: absolute;
              left: 0px;
              top: 0px;
              // transform: translate(-100px, 20%);
              width: 0px;
              height: 0px;
              background-color: none;
              border-radius: 0%;
            }
          }
          p:nth-child(2),
          p:nth-child(4),
          p:nth-child(6),
          p:nth-child(8),
          // p:nth-child(4),
          // p:nth-child(6)
          {
            font-weight: bold;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    @media (max-width: 1399px) {
      .slick-dots {
        bottom: 0px;
        // right: 28%;
      }
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      .slick-dots {
        bottom: 110px;
        // right: 28%;
      }
      .slick-next {
        left: 38%;
      }
      .slick-prev {
        left: 2%;
      }
    }

    @media (max-width: 992px) {
      .slick-dots {
        bottom: 200px;
        // right: 28%;
      }
      .parentImg {
        .slidText {
          h5,
          h6 {
            // font-size: 40px !important;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .slick-dots {
        display: none !important;
      }
      .slick-next {
        left: 102%;
      }
      .slick-prev {
        left: -10%;
      }
    }
    @media (max-width: 825px) {
      .slick-next {
        left: 70%;
      }
      .slick-prev {
        left: 20%;
      }
    }
    @media (max-width: 590px) {
      .slick-next {
        // left: 70%;
        bottom: -100% !important;
      }
      .slick-prev {
        // bottom: -100px;
        // left: 20%;
        bottom: -100% !important;
      }
    }

    // @media (max-width: 768px) {
    //   .slick-next {
    //     left: 40%;
    //   }
    //   .slick-prev {
    //     left: 3%;
    //   }

    //   .slick-dots {
    //     display: none !important;
    //   }
    // }
    // @media (max-width: 768px) {
    //   .slick-next {
    //     left: 28%;
    //   }
    // }

    @media (max-width: 663px) {
      .parentImg {
        // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        // gap: 0px;
      }
      .imgSlide {
        img {
          // max-width: 90%;
        }
      }
    }
    @media (max-width: 590px) {
      .parentImg {
        .slidText {
          height: min-content;
          h5 {
            margin-top: 20px;
            font-size: 20px !important;
          }
          h6 {
            margin-top: 20px;
            font-size: 20px !important;
          }
        }
      }
      .imgSlide {
        img {
          // max-width: 90%;
        }
      }
    }

    @media (max-width: 415px) {
      .parentImg {
        .slidText {
          // h5,
          h6 {
            // margin-top: 20px;
            // font-size: 20px !important;
          }
          p {
            // padding: 0px 30px;
          }
          padding-right: 50px;
        }
      }
    }
  }
}

// .progressBars_container{
//   max-width: 500px;
//   position: relative;
//   top: -70px;

// }

// .progressBars {
//   width: 100%;

// }

// .progress-bar {
//   position: absolute;
//   height: 10px;
//   background-color: $main-color;
//   width: 90%;
//   top: 50%;
//   transform: translateY(-80%);
//   border-radius: 10px;
//   // transition: width 0.3s ease-in-out;
// }

// .boxes {
//   display: flex !important;
//   img {
//     width: 600px;
//   }
// }
