@import "./../../../index.scss";

.whoWeAreHeader {
  text-align: center;
  h2 {
    padding-top: 76px;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    // line-height: 418%; /* 209px */
    letter-spacing: 19px;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: 35px;
      // text-align: start;
      letter-spacing: 10px;
    }
  }
  // h5 {
  //   color: #ffc700;
  //   text-align: center;
  //   font-family: Ubuntu;
  //   font-size: 20px;
  //   font-style: normal;
  //   font-weight: 500;
  //   // line-height: 418%;
  //   letter-spacing: 8px;
  //   text-transform: uppercase;
  // }
}
.whoWeAre {
  @media (min-width: 992px) {
    background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/about/Rectangle0.png")
      no-repeat;
    background-size: cover;
    background-position: 0% -110%;
  }
  padding: 66px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }

  display: grid;
  justify-items: end;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  .whoWeAreRight {
    img {
      padding-top: 40px;
    }
    @media (max-width: 767px) {
      margin: auto;
    }
  }

  .whoWeAreLeft {
    p {
      position: relative;
      color: #000;
      // text-align: justify;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 200%;
      // text-transform: uppercase;
      text-transform: capitalize;

      @media (max-width: 1200px) {
        padding-left: 10px;
      }
      @media (max-width: 575px) {
        padding-left: 20px;
      }
      @media (max-width: 455px) {
        padding-left: 30px;
      }
      span {
        // font-weight: bold;
        display: block;
        color: #000;
        // text-align: justify;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 200%;
        text-transform: uppercase;

        &::before {
          content: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/about/Vetor.svg");
          position: absolute;
          // top: 1%;
          left: -4%;
          @media (max-width: 575px) {
            padding-left: 15px;
          }
          // bottom: auto;
          // background: $main-color;
          // border-radius: 0.5rem;
          // width: 4px;
          // height: 904px;
        }
      }
    }
  }
}

.oursCards {
  padding: 0 0 100px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  div {
    padding: 30px 20px;
  }
  .cardLeft {
    background: #000;
    text-align: center;
    h2 {
      // color: $main-color;
      color: white;
      font-size: 64px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      @media (max-width: 502px) {
        font-size: 40px;
      }
    }
    p {
      color: #f0eff4;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      // line-height: 31px;
      // text-transform: lowercase;
    }
  }
  .cardRight {
    text-align: center;
    background: #e6e6e6;
    h2 {
      color: #000;
      font-size: 64px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      @media (max-width: 502px) {
        font-size: 40px;
      }
    }
    p {
      color: #000;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      // line-height: 31px;
      // text-transform: lowercase;
    }
  }
}
