@import "./../../../index.scss";

.projectsHead {
  // padding-top: 390px;
  padding-top: 30%;
  @media (max-width: 991px) {
    padding-top: 190px;
  }
  // background: url("./../../../assets/Rectangle 5446.png") no-repeat;
  background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/Rectangle 25446.png")
    no-repeat;
  background-size: cover;
  height: 100vh;
  @media (max-width: 1000px) {
    background: url("https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentprojects/projectsHeaderMopile.png")
      no-repeat;
    background-size: cover;
    // height: calc(100vh - 110px);
    // width: 100vw;
  }

  h1 {
    padding-bottom: 20px;
    color: $main-color;
    font-size: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    @media (max-width: 502px) {
      font-size: 45px;
      text-align: start;
      // letter-spacing: 10px;
    }
  }
  h6 {
    max-width: 900px;
    padding-bottom: 20px;
    // color: #fffffe;
    font-size: 18px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 2px;
  }
  .filters {
    // button {
    //   padding: 7px;
    //   border-radius: 6px;
    //   background: #d9d9d9;
    //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    //   img {
    //     padding-left: 10px;
    //   }
    // }
    .filterRadios {
      // padding-top: 36px;
      // div {
      //   display: inline-block;
      //   padding-top: 10px;
      // }
      // color: white;
      // @media (max-width: 991px) {
      //   padding-right: 130px !important;
      //   .radiosButtom {
      //     padding-right: 110px !important;
      //   }
      // }
      // @media (max-width: 767px) {
      //   padding-right: 0px !important;
      //   max-width: 350px;
      //   .radiosButtom {
      //     padding-right: 110px !important;
      //   }
      // }

      // label {
      //   padding-right: 10px;
      //   padding-left: 5px;
      //   color: #050000;
      //   font-family: Montserrat;
      //   font-size: 15px;
      //   font-style: normal;
      //   font-weight: 700;
      //   line-height: normal;
      //   text-transform: uppercase;
      // }
      // span {
      //   display: block;
      //   padding: 5px;
      // }
    }
  }
}
